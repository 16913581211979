import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ProjectDetailView from "../ProjectDetailView";
import { Link, useHistory } from "react-router-dom";
import { ProjectsPageContext } from "../../pages/ProjectsPage/context";
import { useContext } from "react";

const useStyles = makeStyles((StyleTheme) => ({
  accordion: {
    width: "100%",
  },
  heading: {
    fontSize: StyleTheme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: StyleTheme.typography.pxToRem(15),
    color: StyleTheme.palette.primary.main,
  },
}));

export default function Project({ data, open }) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(open);
  const history = useHistory();
  const { onUpdate } = useContext(ProjectsPageContext);

  useEffect(() => {
    open ? setOpenModal(true) : setOpenModal(false);
  }, [open]);

  return (
    <Container disableGutters>
      <Link
        onClick={() => setOpenModal(true)}
        to={"/projects/" + data.clientName + "/" + data.name}
        className={classes.accordion}
        style={{ textDecoration: "none" }}
      >
        <Accordion expanded={false}>
          <AccordionSummary>
            <Typography className={classes.heading}>
              {data.clientName}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {data.name}
            </Typography>
          </AccordionSummary>
        </Accordion>
      </Link>
      <ProjectDetailView
        open={openModal}
        onClose={() => {
          history.push("/projects");
          setOpenModal(false);
        }}
        id={data.id}
        onUpdate={onUpdate}
      />
    </Container>
  );
}
