import React, { useContext, useEffect } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  CircularProgress,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  FormLabel,
  styled
} from "@mui/material";
import DashboardFilter from "../../constants/DashboardFilter";
import useRegions from "../../hooks/useRegions";
import useMarkets from "../../hooks/useMarkets";
import { useRegionStorage } from "../../hooks/useRegionStorage";
import { useMarketStorage } from "../../hooks/useMarketStorage";
import { useFilterStorage } from "../../hooks/useFilterStorage";
import { ApiClientContext } from "../../../ApiClient/context";
import { useQueryClient } from "@tanstack/react-query";
import {useHistory} from "react-router-dom";

interface RegionAndMarketSelectorProps {
  showLabels: boolean
}

const FilterTypeControlLabel = styled(Radio)`
  padding-top: ${({ theme }) => theme.spacing(0)};
  padding-bottom: ${({ theme }) => theme.spacing(0)};
`;

function RegionAndMarketSelector({ showLabels = false }: RegionAndMarketSelectorProps) {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();
  const [selectedRegion, setSelectedRegion] = useRegionStorage();
  const [selectedMarket, setSelectedMarket] = useMarketStorage();
  const [selectedFilter, setSelectedFilter] = useFilterStorage();
  const { data: regions } = useRegions();
  const { data: markets } = useMarkets(true);
  const history = useHistory();

  useEffect(() => {
    if (!selectedFilter) setSelectedFilter(DashboardFilter.Region)
  }, [selectedFilter, setSelectedFilter])

  useEffect(() => {
    if (!selectedRegion && Array.isArray(regions) && regions.length > 0) setSelectedRegion(regions[0])
  }, [regions, selectedRegion, setSelectedRegion])

  useEffect(() => {
    if (!selectedMarket && Array.isArray(markets) && markets.length > 0) setSelectedMarket(markets[0])
  }, [markets, selectedMarket, setSelectedMarket])

  async function clearCachedDeliveryReportData() {
    await queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === "deliveryReportsSummary" ||
        query.queryKey[0] === "availableDeliveryReportDates" ||
        query.queryKey[0] === "availableMarketDeliveryReportDates" ||
        query.queryKey[0] === "loggedInUser" // awkward but required to cause the navbar to reload
    });
  }

  const handleRegionChange = async (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    apiClient.clearPortfoliosCache();
    setSelectedRegion(regions?.find((region) => region.shortName === event.target.value));
    await clearCachedDeliveryReportData();
  }

  const handleMarketChange = async (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setSelectedMarket(markets?.find((market) => market.name === event.target.value));
    await clearCachedDeliveryReportData();
  }

  const handleFilterChange = async (event: SelectChangeEvent<string>) => {
    history.go(0)
    const { value } = event.target;
    if (value === DashboardFilter.Market || value === DashboardFilter.Region) {
      setSelectedFilter(value)
      await clearCachedDeliveryReportData();
    }
  }

  return (
    <div
      data-testid="regionAndMarketSelector"
    >
      {(!regions || !markets) ? <Box display="flex" justifyContent="center" data-testid="load-icon">
        <CircularProgress role="progress-icon" />
      </Box> :
        <>
          <Grid container spacing={0} alignItems={showLabels ? "end" : "center"}>
            <Grid item>
              <FormControl
                variant={showLabels ? "outlined" : "standard"}
                fullWidth={false}
                style={{
                  alignItems: 'start'
                }}
              >
                {showLabels && <FormLabel htmlFor="filter-select">Select by</FormLabel>}
                <RadioGroup
                  aria-labelledby="filter-select"
                  name="filter-select-radio-group"
                  value={selectedFilter}
                  onChange={handleFilterChange}
                >
                  <FormControlLabel
                    name={DashboardFilter.Region}
                    value={DashboardFilter.Region}
                    control={<FilterTypeControlLabel />}
                    label={DashboardFilter.Region}
                  />
                  <FormControlLabel
                    name={DashboardFilter.Market}
                    value={DashboardFilter.Market}
                    control={<FilterTypeControlLabel />}
                    label={DashboardFilter.Market}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item flexGrow={1}>
              {selectedFilter === DashboardFilter.Region &&
                <FormControl
                  variant={showLabels ? "outlined" : "standard"}
                  fullWidth={true}
                >
                  {showLabels && <InputLabel htmlFor="region-select">Region</InputLabel>}
                  <Select
                    id="region-select"
                    label="Region"
                    value={selectedRegion ? selectedRegion.shortName : ""}
                    onChange={handleRegionChange}
                    inputProps={{ "aria-label": "select region" }}
                  >
                    {regions && regions.map((item) => (
                      <MenuItem key={item.id} value={item.shortName}>
                        {item.shortName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }

              {selectedFilter === DashboardFilter.Market &&
                <FormControl
                  variant={showLabels ? "outlined" : "standard"}
                  fullWidth={true}
                >
                  {showLabels && <InputLabel htmlFor="market-select">Market</InputLabel>}
                  <Select
                    id="market-select"
                    label="Market"
                    value={selectedMarket ? selectedMarket.name : ""}
                    onChange={handleMarketChange}
                    inputProps={{ "aria-label": "select market" }}
                  >
                    {markets && markets.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            </Grid>
          </Grid>
        </>}
    </div>
  )
}

export default RegionAndMarketSelector;
export type { RegionAndMarketSelectorProps };
