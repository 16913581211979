import React from "react";
import logo from "../../Images/ProjectorLogo.svg";
import {
  AppBar,
  Button,
  Hidden,
  Toolbar,
  makeStyles,
  Grid,
  Box,
  Icon,
} from "@material-ui/core";
import RegionSelector from "../../shared/components/RegionSelector";
import "@fontsource/bitter";
import { NavLink } from "react-router-dom";
import useLoggedInUser from "../../shared/hooks/useLoggedInUser";

const useStyles = makeStyles(() => ({
  appBar: {
    zIndex: 2,
    backgroundColor: "#EDF1F3",
  },
  menuButton: {
    textTransform: "none",
    textDecoration: "none",
    fontFamily: "Bitter",
    fontWeight: 400,
    color: "#003D4F",
    "&:hover": {
      background: "none",
    },
  },
  activeMenuButton: {
    fontFamily: "Bitter",
    fontWeight: "bold",
    color: "#003D4F",
    textDecoration: "underline",
    textUnderlineOffset: ".5em",
    "&:hover": {
      background: "none",
    },
  },
  menuIcon: {
    textTransform: "none",
    textDecoration: "none",
    fontFamily: "Bitter",
    fontWeight: 400,
    color: "#003D4F",
    margin: 5,
  },
  menuUl: {
    display: "flex",
    listStyle: "none",
  },
  menuLi: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    marginRight: 20,
  },
  logo: {
    width: 100,
    marginRight: 10,
    margin: 10,
  },
  root: {
    height: "auto",
  },
}));

function NavBarItem({ className, linkTo, label }) {
  const classes = useStyles();

  return (
    <li className={classes.menuLi}>
      <Icon
        classes={{ root: classes.root }}
        className={`${className} ${classes.menuIcon}`}
        fontSize="inherit"
      />
      <NavLink
        to={linkTo}
        className={(state) =>
          state ? classes.activeMenuButton : classes.menuButton
        }
      >
        {label}
      </NavLink>
    </li>
  );
}

export default function NavigationBar() {
  const { data: loggedInUser } = useLoggedInUser();

  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" color="inherit" elevation={0}>
        <Toolbar className={classes.appBar}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              <a href="/">
                <img src={logo} className={classes.logo} alt="Projector Logo" />
              </a>
            </Box>
            <Box display="flex">
              <ul className={classes.menuUl}>
                <NavBarItem
                  className="ri-function-line"
                  linkTo="/delivery-report"
                  label="Dashboard"
                />
                <NavBarItem
                  className="ri-stack-line"
                  linkTo="/projects"
                  label="Projects"
                />
                <NavBarItem
                  className="ri-line-chart-line"
                  linkTo="/trends"
                  label="Trends"
                />
              </ul>
            </Box>
            <Grid item>
              <Box display="flex" sx={{ gap: "1rem" }}>
                <RegionSelector />
                <Button
                  className={classes.menuButton}
                  startIcon={
                    <Icon
                      classes={{ root: classes.root }}
                      className="ri-user-line"
                    />
                  }
                  disableRipple
                >
                  <Hidden xsDown>{loggedInUser?.name || "Account"}</Hidden>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
