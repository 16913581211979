import ProjectorLogo from "../../Images/ProjectorLogo.svg";
import {
  AppBar,
  Button,
  Toolbar,
  makeStyles,
  Box,
  Icon,
} from "@material-ui/core";
import RegionSelector from "../../shared/components/RegionSelector";
import "@fontsource/bitter";
import { NavLink, Link } from "react-router-dom";
import useLoggedInUser from "../../shared/hooks/useLoggedInUser";
import config from "../../config";
import DashboardFilter from "../../shared/constants/DashboardFilter";
import RegionAndMarketSelector from "../../shared/components/RegionAndMarketSelector";
import { List, ListItem } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 2,
    backgroundColor: "#EDF1F3",
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  appBarSection: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  menuButton: {
    textTransform: "none",
    textAlign: "right",
    textDecoration: "none",
    fontFamily: "Bitter",
    fontWeight: 400,
    color: "#003D4F",
    "&:hover": {
      background: "none",
    },
  },
  activeMenuButton: {
    fontFamily: "Bitter",
    fontWeight: "bold",
    color: "#003D4F",
    textDecoration: "underline",
    textUnderlineOffset: ".5em",
    "&:hover": {
      background: "none",
    },
  },
  menuIcon: {
    textTransform: "none",
    textDecoration: "none",
    fontFamily: "Bitter",
    fontWeight: 400,
    color: "#003D4F",
    margin: 5,
  },
  menuItems: {
    display: "flex",
    gap: theme.spacing(2),
  },
  menuItem: {
    justifyItems: "center",
    alignItems: "center",
    padding: theme.spacing(0),
  },
  logo: {
    width: 100,
    margin: 10,
  },
  root: {
    height: "auto",
  },
}));
type NavBarItemProps = {
  className: string,
  linkTo: string,
  label: string
}
function NavBarItem({ className, linkTo, label }: NavBarItemProps) {
  const classes = useStyles();

  return (
    <ListItem className={classes.menuItem} alignItems="center" disableGutters={true}>
      <Icon
        classes={{ root: classes.root }}
        className={`${className} ${classes.menuIcon}`}
        fontSize="inherit"
      />
      <NavLink
        to={linkTo}
        className={(state) =>
          state ? classes.activeMenuButton : classes.menuButton
        }
      >
        {label}
      </NavLink>
    </ListItem>
  );
}

export default function MarketNavigationBar() {
  const { data: loggedInUser } = useLoggedInUser();
  const storageFilter = localStorage.getItem("filter");
  const selectedFilter = storageFilter
    ? JSON.parse(storageFilter)
    : DashboardFilter.Region;

  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" color="inherit" elevation={0}>
        <Toolbar className={classes.appBar}>
          <Box className={classes.appBarSection}>
            <Link to="/">
              <img
                src={ProjectorLogo}
                className={classes.logo}
                alt="Projector Logo"
              />
            </Link>
            <List className={classes.menuItems}>
              <NavBarItem
                className="ri-function-line"
                linkTo="/delivery-report"
                label="Dashboard"
              />
              {(selectedFilter === DashboardFilter.Region || config.featureToggles.enableMarketProjects) &&
                  <NavBarItem
                  className="ri-stack-line"
                  linkTo="/projects"
                  label="Projects"
                />}

              {selectedFilter !== DashboardFilter.Market ? (
                <NavBarItem
                  className="ri-line-chart-line"
                  linkTo="/trends"
                  label="Trends"
                />
              ) : null}
            </List>
          </Box>
          <Box className={classes.appBarSection}>
            {config.featureToggles.enableMarketDashboard ? (
              <RegionAndMarketSelector showLabels={false} />
            ) : (
              <RegionSelector />
            )}
            <Button
              className={classes.menuButton}
              startIcon={
                <Icon
                  classes={{ root: classes.root }}
                  className="ri-user-line"
                />
              }
              disableRipple
            >
              <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
                {loggedInUser?.name || "Account"}
              </Box>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
