import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import config from "../../../config";
import ApiClientManager from "../../../Components/ApiClientManager";
import HomePage from "../../../pages/HomePage";
import HelpPage from "../../../pages/HelpPage";
import ProjectsPage from "../../../pages/ProjectsPage";
import DeliveryReportPage from "../../../pages/DeliveryReportPage";
import NavigationBar from "../../../Components/NavigationBar";
import MarketsNavigationBar from "../../../Components/NavigationBar/MarketsNavigationBar";
import SideBar from "../../../Components/SideBar";
import mockOktaAuth from "../../utils/MockOktaAuth";
import ContributingPage from "../../../pages/ContributingPage";
import TrendsPage from "../../../pages/TrendsPage";
import UsersPage from "../../../pages/UsersPage";
import "remixicon/fonts/remixicon.css";

const oktaAuth =
  process.env.REACT_APP_ENV === "dev-no-auth"
    ? mockOktaAuth
    : new OktaAuth(config.oidc);

const Layout = ({ component: Component, ...rest }) => {
  return (
    <SecureRoute
      {...rest}
      render={(matchProps) => (
        <>
          {config.featureToggles.enableMarketDashboard ? (
            <MarketsNavigationBar />
          ) : (
            <NavigationBar />
          )}
          <div>
            <SideBar />
            <div style={{ marginLeft: "4em" }}>
              <Component {...matchProps} />
            </div>
          </div>
        </>
      )}
    />
  );
};

const Main = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/implicit/callback" component={LoginCallback} />
        <ApiClientManager>
          <SecureRoute path="/" exact component={HomePage} />
          <Layout path="/help" component={HelpPage} />
          <Layout path="/contributing" component={ContributingPage} />
          <Layout
            path="/projects/:clientName?/:projectName?"
            component={ProjectsPage}
          />
          <Layout
            path="/delivery-report/:type?/:id?"
            component={DeliveryReportPage}
          />
          <Layout path="/trends" component={TrendsPage} />
          <Layout path="/users" component={UsersPage} />
        </ApiClientManager>
      </Switch>
    </Security>
  );
};

export default Main;
